import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export enum ChainId {
  MAINNET = 56,
  BSCTESTNET = 97
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

// TODO: Idex factory
const MAINNET_IDEX_FACTORY = '0xTODO';
const TESTNET_IDEX_FACTORY = '0x574102DADE63Aa0af9EC5f276c8CAB752921fe1A';
// const MAINNET_PANCAKE_FACTORY = '0xBCfCcbde45cE874adCB698cC183deBcF17952812';
// const TESTNET_PANCAKE_FACTORY = '0x6725F303b657a9451d8BA641348b6761A6CC7a17';

// Pancake
// export const FACTORY_ADDRESS = {
//   [ChainId.MAINNET]: MAINNET_PANCAKE_FACTORY,
//   [ChainId.BSCTESTNET]: TESTNET_PANCAKE_FACTORY
// }

export const FACTORY_ADDRESS = {
  [ChainId.MAINNET]: MAINNET_IDEX_FACTORY,
  [ChainId.BSCTESTNET]: TESTNET_IDEX_FACTORY
}

// Idex
export const INIT_CODE_HASH = '0xf7937aef90ce738ffa8440379f2b14cdd5765ec3d31921645b47e40cc015c791'

// Pancake
// export const INIT_CODE_HASH = '0xd0d4c4cd0848c93cb4fd1f498d7013ee6bfb25783ea21593d5834f5d250ece66'

// Pan
export const WRAPPED_BNB_ADDRESS_MAINNET = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
// Wrapped ETH (BNB)
export const WRAPPED_BNB_ADDRESS_TESTNET = '0x1287067188fbB1ca0F7e25B3E6264Aa06aaC4100' // ?'0xaE8E19eFB41e7b96815649A6a60785e1fbA84C1e'
// export const WRAPPED_ETH_ADDRESS_TESTNET = '0x1287067188fbb1ca0f7e25b3e6264aa06aac4100'


export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _998 = JSBI.BigInt(998)
export const _1000 = JSBI.BigInt(1000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}
